<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div class="ml-2">
                                        <div class="text-2xl text-primary font-bold">My Account</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div class="card  nice-shadow-6">
                            <div >
                                <div class="relative-position" style="min-height:100px">
                                    <div class="grid">
                                        <div class="col-12">
                                            <template v-if="!loading && item">
                                                <div v-if="!loading">
                                                    <div class="p-4 mb-4">
                                                        <div class="grid">
                                                            <div class="col-fixed" style="width:120x">
                                                                <image-viewer :ratio="1/1" image-size="medium" :src="$UserPhoto"></image-viewer>
                                                            </div>
                                                            <div class="col">
                                                                <div class="text-2xl text-primary"> {{ $UserName }} </div>
                                                                <div class="text-sm text-gray-500"> {{ $UserEmail }} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <TabView v-model:activeIndex="activeTab">
                                                            <TabPanel>
                                                                <template #header>
                                                                    <i class="pi pi-user mr-2"></i>
                                                                    <span>Account Detail</span>
                                                                </template>
                                                                <div>
                                                                    <div v-if="!loading && ready">
                                                                        <div class="grid align-items-center">
                                                                            <div class="col">
                                                                                <div class="text-500 text-sm mb-1">User Id: </div>
                                                                                <div class="font-bold">{{ item.user_id }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="grid align-items-center">
                                                                            <div class="col">
                                                                                <div class="text-500 text-sm mb-1">Name: </div>
                                                                                <div class="font-bold">{{ item.name }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="grid align-items-center">
                                                                            <div class="col">
                                                                                <div class="text-500 text-sm mb-1">Username: </div>
                                                                                <div class="font-bold">{{ item.username }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="grid align-items-center">
                                                                            <div class="col">
                                                                                <div class="text-500 text-sm mb-1">Email: </div>
                                                                                <div class="font-bold">{{ item.email }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div v-if="loading" class="p-3 text-center">
                                                                        <ProgressSpinner style="width:50px;height:50px" />  
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel>
                                                                <template #header>
                                                                    <i class="pi pi-pencil  mr-2"></i>
                                                                    <span>Edit Account</span>
                                                                </template>
                                                                <div class="reset-grid">
                                                                    <account-edit-page></account-edit-page>
                                                                    </div>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <template #header>
                                                                        <i class="pi pi-lock  mr-2"></i>
                                                                        <span>Change Password</span>
                                                                    </template>
                                                                    <div class="reset-grid">
                                                                        <change-password-page></change-password-page>
                                                                    </div>
                                                                </TabPanel>
                                                            </TabView>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div  class="q-pa-lg text-center">
                                                        <ProgressSpinner style="width:50px;height:50px" />  
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
	import { defineAsyncComponent, ref } from "vue";
	import { PageMixin } from "../../mixins/page.js";
	import { ViewPageMixin } from "../../mixins/viewpage.js";
	import  AccountEditPage  from "./accountedit.vue";
	import  ChangePasswordPage  from "./changepassword.vue";
	export default {
		name: 'accountviewUsersPage',
		components: {
			AccountEditPage,
			ChangePasswordPage,
		},
		mixins: [PageMixin, ViewPageMixin ],
		props: {
			pageName: {
				type : String,
				default : 'users',
			},
			routeName : {
				type : String,
				default : 'usersaccountview',
			},
			pagePath: {
				type : String,
				default : 'account',
			},
			apiPath: {
				type : String,
				default : 'account',
			},
		},
		data() {
            return {
				item: {
					default :{
					},
				},
				activeTab: 0,
			}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "My Account"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["users/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("users/setCurrentRecord", value);
				},
			},
		},
		methods: {
				getActionMenuModel(data){
					return [
  {
    label: "Edit",
    to: `/users/edit/${data.user_id}`,
    icon: "pi pi-pencil"
  }
]
				},
		},
		watch: {
			$route (to, from){
				//only fetch data when navigated to this page
				if(to.name == this.routeName){
					//this.load();
				}
			},
		},
		mounted() {
		},
	};
</script>
<style scoped>
</style>
